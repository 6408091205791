import { mapActions, mapGetters, mapMutations } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import "vue2-datepicker/locale/uk";
// import "vue2-datepicker/locale/af";
// import "vue2-datepicker/locale/ru";
import banner from "../home/components/banner/index.vue";
import { globalLoader } from "@/helpers/variables";
import { downloadFileFrom } from "@/helpers";
import moment from "moment";

export default {
    name: "seminars",
    components: {
        DatePicker,
        banner,
    },
    data() {
        return {
            loadingSeminars: false,
            filteringSeminars: false,
            filters: null,
            seminarsList: [],
            paginator: {
                hasNextPage: false,
                page: 1,
                perPage: 10,
            },
            dates: null,
            city: "",
            seminar: "",
            theme: "",
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: false,
            },
        };
    },
    async created() {
        globalLoader(true);

        await Promise.all([
            this.fetchPage("seminars"),
            this.fetchFilters().then(filters => (this.filters = filters)),
            this.loadSeminars(),
        ]);

        globalLoader(false);
    },
    computed: {
        ...mapGetters({
            pageContent: "pages/page",
            isAuthenticated: "auth/isAuthenticated",
            isProfessional: "profile/isProfessional",
        }),
        checkPdfAvailable() {
            const cityFileExists = !!this.city && this.filters.cities.find(item => item.title === this.city)?.file;
            return !cityFileExists;
        },
    },
    watch: {
        dates() {
            this.$refs.errorForm.textContent = "";
        },
        city() {
            this.$refs.errorForm.textContent = "";
        },
        seminar() {
            this.$refs.errorForm.textContent = "";
        },
        search() {
            this.$refs.errorForm.textContent = "";
        },
    },
    methods: {
        ...mapMutations({
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeSeminarRegistration: "popups/CHANGE_SEMINAR_REGISTRATION_POPUP",
            saveCurrentSeminar: "seminars/SAVE_CURRENT_SEMINAR",
            changeSeminarDetailsPopup: "popups/CHANGE_SEMINAR_DETAILS_POPUP",
        }),
        ...mapActions({
            fetchPage: "pages/GET_PAGE",
            fetchSeminars: "seminars/GET_SEMINARS",
            fetchFilters: "seminars/GET_SEMINAR_FILTERS",
        }),
        getCurrentMonthUK() {
            moment.locale("uk");
            return moment().format("MMMM YYYY");
        },
        async downloadPdf() {
            const link = this.filters.cities.find(item => item.title === this.city).file;
            await downloadFileFrom(link, this.$t('seminarFile') + ".pdf");
        },
        showSeminarPopup(detail, title) {
            this.changeSeminarDetailsPopup({status: true, payload: {
                title: title,
                detail: detail,
            }});
        },
        async resetFilters() {
            this.filteringSeminars = true;

            this.dates = null;
            this.city = "";
            this.seminar = "";
            this.theme = "";

            this.resetPage();
            await this.loadSeminars();

            this.filteringSeminars = false;
        },
        disabledData(date) {
            let today = new Date();
            today.setDate(today.getDate() - 1);
            return date < today;
        },
        resetPage() {
            this.paginator.page = 1;
        },
        async loadSeminars() {
            this.loadingSeminars = true;

            const seminarsData = await this.fetchSeminars({
                page: this.paginator.page,
                perPage: this.paginator.perPage,
                ...this.getCurrentFilters(),
            });

            if (this.paginator.page === 1) {
                this.seminarsList = [];
            }

            this.seminarsList.push(...seminarsData.data);

            this.paginator.hasNextPage = !!seminarsData.links.next;

            this.loadingSeminars = false;
        },
        paginateSeminars() {
            this.paginator.page += 1;
            this.loadSeminars();
        },
        /** @param {Seminar} seminar */
        seminarRegister(seminar) {
            if (this.isProfessional) {
                this.saveCurrentSeminar(seminar);
                this.changeSeminarRegistration(true);
            } else {
                this.$toasted.error(this.$t('seminarsAreAvailableOnlyForProfessionals'));
            }
        },
        /** @return {IFiltersType} */
        getCurrentFilters() {
            /** @type {IFiltersType} */
            let filters = {};
            if (this.dates) {
                let dayFrom = this.dates[0].getDate(),
                    monthFrom = this.dates[0].getMonth() + 1,
                    yearFrom = this.dates[0].getUTCFullYear(),
                    dayTo = this.dates[1].getDate(),
                    monthTo = this.dates[1].getMonth() + 1,
                    yearTo = this.dates[1].getUTCFullYear();

                filters.date_from = `${yearFrom}-${monthFrom}-${dayFrom}`;
                filters.date_to = `${yearTo}-${monthTo}-${dayTo}`;
            }
            if (this.city) {
                let cityId = this.filters.cities.find(el => el.title === this.city);
                filters.city = cityId.id;
            }
            if (this.seminar) {
                let seminar = this.filters.types.find(el => el.title === this.seminar);
                filters.type = seminar.id;
            }
            if (this.theme) {
                let themeId = this.filters.themes.find(el => el.title === this.theme);
                filters.theme = themeId.id;
            }

            return filters;
        },
        async submit() {
            if (!this.dates && !this.city && !this.seminar && !this.theme) {
                this.$refs.errorForm.textContent = this.$t("allFiltersEmpty");
            } else {
                this.filteringSeminars = true;
                this.resetPage();
                await this.loadSeminars();
                this.filteringSeminars = false;
            }
        },
    },
};
